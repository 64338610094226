<template>
  <div id="progress-bar-container" class="progress-bar-container">
    <div class="row">
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
    </div>
    <div class="row">
      <div class="progress-bar col-4" style="width: 98.7%">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
    </div>
    <div class="row">
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
    </div>
    <div class="row">
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
      <div class="progress-bar col-4">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
    </div>
    <div class="row">
      <div class="progress-bar col-4" style="width: 98.7%">
        <div class="progress-inside" style="width: 50%; margin-top: 30px"></div>
        <div class="progress-inside" style="width: 80%"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#progress-bar-container {
  width: 100%;
  overflow: hidden;
  height: 1000px;
}

.progress-bar {
  position: relative;
  height: 130px;
  background: #e6ede957;
  width: 99%;
  margin: 15px 4px 4px 2px;
  overflow: hidden; /* Hide overflow to keep animation inside */
}

.progress-inside {
  position: relative;
  background-color: rgb(205, 216, 216);
  border-radius: 15px;
  height: 30px;
  margin: 0px 10px 2px 15px;
  overflow: hidden;
  display: inline-block;
  background: linear-gradient(
    to right,
    rgb(247, 255, 252) 0%,
    rgba(217, 219, 219, 0.897) 10%,
    rgb(220 225 225) 10%,
    rgb(210 221 221 / 81%) 100%
  );

  background-size: 200% 100%;
  animation: moveColor 1ms linear infinite;
}

@keyframes moveColor {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: -100% 0%;
  }
}
</style>
