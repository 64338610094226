<template>
  <ItemList
    :columns="columns"
    :items="items"
    :group-key="groupKey"
    @groupItemSelected="updateGroupItemSelection"
    @group="updateSelectionGroup"
    @action="groupRequestAction"
    @openSubWorkflow="updateSelectedSubWorkflow"
  >
    <template #avatar="{ item }">
      <div class="row">
        <CheckboxField
          v-if="mode === 'inbox' && item.key && item.checkboxRequired"
          :value="item.checked"
          class="col q-mr-md"
          @input="$emit('selected', item)"
        />
        <BaseIcon
          :name="item.starred ? 'mdi-star' : 'mdi-star-outline'"
          :color="darkTheme ? 'secondary' : 'primary'"
          class="icon col"
          @click="$emit('starred', item)"
        />
      </div>
    </template>
    <template #title="{ item }">
      <div class="row">
        <div
          class="col-auto ellipsis"
          :style="item.requestNo.length > 50 ? 'max-width: 250px' : ''"
        >
          <span
            class="stat"
            :class="item.link ? 'text-underline' : ''"
            @click.stop="$emit('show', item.id, item.workflowId)"
          >
            {{ item.requestNo || "-" }}
          </span>
          <template v-if="item.jiraIssueInfo && item.jiraIssueInfo.id">
            <BaseIcon name="mdi-circle-small" color="secondary" />
            <a
              v-tooltip.top="'JIRA Issue Link'"
              :href="getJIRALink(item.jiraIssueInfo)"
              target="_blank"
              style="color: inherit"
            >
              REF# ID: {{ item.jiraIssueInfo.id }}
            </a>
            <BaseIcon name="mdi-circle-small" color="secondary" />
            <span> KEY: {{ item.jiraIssueInfo.key }} </span>
            <template v-if="item.jiraIssueInfo.assignee">
              <BaseIcon name="mdi-circle-small" color="secondary" />
              <span class="text-primary text-bold"
                >ASSIGNEE: {{ item.jiraIssueInfo.assignee }}</span
              >
            </template>
            <template v-if="item.jiraIssueInfo.issueStatus">
              <BaseIcon name="mdi-circle-small" color="secondary" />
              <span class="text-primary text-bold"
                >STATUS: {{ item.jiraIssueInfo.issueStatus }}</span
              >
            </template>
          </template>
        </div>
        <!-- <div
          v-if="mode === 'inbox' && item.mlPrediction"
          class="col-auto q-mt-sm"
        >
          <q-chip
            v-if="!item.mlCondition"
            outline
            color="pink"
            class="chips pink-hover"
          >
            <q-avatar icon="mdi-robot" color="pink" text-color="white" />
            {{ item.mlPrediction }}
          </q-chip>
        </div> -->
        <!-- <div v-if="mode === 'inbox' && item.mlCondition">
          <template v-for="cond in item.mlCondition.split(',')">
            <q-chip :key="cond" outline color="gray" class="chips gray-hover">
              <q-avatar
                icon="mdi-information-variant"
                color="gray"
                text-color="white"
              />
              {{ cond }}
            </q-chip>
          </template>
        </div> -->
      </div>
      <div
        v-if="
          isGOTOTenant &&
          (mode === 'inbox' || mode === 'common' || mode === 'payment') &&
          !item.mlPrediction &&
          item.mlCondition
        "
        class="row q-pt-sm"
      >
        <template v-if="item.mlCondition.indexOf('[') > -1">
          <div
            v-for="row in JSON.parse(item.mlCondition)"
            :key="item.processId + '' + row.fieldName"
            @click.stop="$emit('validation', item.processId)"
          >
            <q-chip
              outline
              :color="
                row.status === 'Matched'
                  ? 'green'
                  : row.status === 'Not Matched'
                  ? 'red'
                  : 'orange'
              "
              class="chips gray-hover"
              :class="
                row.status === 'Matched'
                  ? 'green-hover'
                  : row.status === 'Not Matched'
                  ? 'red-hover'
                  : 'orange-hover'
              "
            >
              <q-avatar
                icon="mdi-robot"
                class="q-mr-sm"
                :color="
                  row.status === 'Matched'
                    ? 'green'
                    : row.status === 'Not Matched'
                    ? 'red'
                    : 'orange'
                "
                text-color="white"
              />
              {{ row.fieldValue }}
            </q-chip>
          </div>
        </template>
        <template v-else>
          <template v-for="cond in item.mlCondition.split(',')">
            <q-chip :key="cond" outline color="pink" class="chips pink-hover">
              <q-avatar
                icon="mdi-information-variant"
                class="q-mr-sm"
                color="pink"
                text-color="white"
              />
              {{ cond }}
            </q-chip>
          </template>
        </template>
      </div>
    </template>
    <template #mlcondition="{ item }">
      <div
        v-if="
          (mode === 'inbox' || mode === 'common' || mode === 'payment') &&
          item.mlCondition
        "
      >
        <template v-for="cond in item.mlCondition.split(',')">
          <template v-if="cond.indexOf('Extracted') > -1">
            <q-chip :key="cond" outline color="green" class="chips green-hover">
              <q-avatar
                icon="mdi-information-variant"
                color="green"
                text-color="white"
              />
              {{ cond }}
            </q-chip>
          </template>
          <template v-else-if="cond.indexOf('Processing') > -1">
            <q-chip
              :key="cond"
              outline
              color="orange"
              class="chips orange-hover"
            >
              <q-avatar
                icon="mdi-information-variant"
                color="orange"
                text-color="white"
              />
              {{ cond }}
            </q-chip>
          </template>
          <template v-else>
            <q-chip :key="cond" outline color="pink" class="chips pink-hover">
              <q-avatar
                icon="mdi-information-variant"
                color="pink"
                text-color="white"
              />
              {{ cond }}
            </q-chip>
          </template>
        </template>
      </div>
    </template>
    <template #actions="{ item }">
      <div class="row" style="width: 100px">
        <BaseActionButton
          v-tooltip.top="'history'"
          icon="mdi-history"
          color="primary"
          is-flat
          no-border
          class="icon"
          @click="$emit('history', item.id)"
        />

        <BaseActionButton
          v-tooltip.top="'attachments'"
          is-flat
          no-border
          icon="mdi-attachment"
          color="primary"
          class="q-mr-sm"
          @click.stop="$emit('attachments', item)"
        >
          <span v-if="item.attachmentCount" class="badge"
            >{{ item.attachmentCount }}
          </span>
        </BaseActionButton>
        <BaseActionButton
          v-tooltip.top="'comments'"
          is-flat
          no-border
          icon="mdi-comment-text-outline"
          color="primary"
          class="q-mr-sm"
          @click.stop="$emit('comments', item)"
        >
          <span v-if="item.commentsCount" class="badge"
            >{{ item.commentsCount }}
          </span>
        </BaseActionButton>
      </div>
    </template>
    <template #subactions="{ item }">
      <div class="row" style="width: 100px">
        <BaseActionButton
          v-tooltip.top="'history'"
          icon="mdi-history"
          color="primary"
          is-flat
          no-border
          class="icon"
          style="margin: auto"
          @click="subWorkflowHistory(item)"
        />

        <BaseActionButton
          v-tooltip.top="'attachments'"
          is-flat
          no-border
          icon="mdi-attachment"
          color="primary"
          class="q-mr-sm"
          @click.stop="$emit('subAttachments', item)"
        >
          <span v-if="item.attachmentCount" class="badge"
            >{{ item.attachmentCount }}
          </span>
        </BaseActionButton>

        <BaseActionButton
          v-tooltip.top="'comments'"
          is-flat
          no-border
          icon="mdi-comment-text-outline"
          color="primary"
          class="q-mr-sm"
          @click.stop="$emit('subComments', item)"
        >
          <span v-if="item.commentsCount" class="badge"
            >{{ item.commentsCount }}
          </span>
        </BaseActionButton>
      </div>
    </template>
  </ItemList>
</template>

<script>
import ItemList from "./ItemList.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import { mapState } from "vuex";

export default {
  name: "ProcessList",

  components: { ItemList, CheckboxField },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    groupKey: {
      type: Array,
      default: () => [],
    },

    groupItemSelected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(["darkTheme"]),

    isGOTOTenant() {
      let origin = location.origin;
      if (origin === "https://app.ezofis.com") {
        return true;
      }
      return false;
    },
  },

  methods: {
    updateSelectionGroup(group) {
      this.$emit("group", group);
    },

    groupRequestAction(action, group) {
      this.$emit("action", action, group);
    },

    getJIRALink(link) {
      let url = new URL(link.self);
      let origin = url.origin.replace(
        "https://jira-external-tools-integration.getgotools.net",
        "https://jira.ops.expertcity.com"
      );
      return `${origin}/browse/${link.key}`;
    },

    updateSelectedSubWorkflow(item) {
      this.$emit("openSubWorkflow", item);
    },

    updateGroupItemSelection(value) {
      this.$emit("update:groupItemSelected", value);
    },

    subWorkflowHistory(item) {
      this.$emit("SubHistory", item.processId, item.parentProcessID);
    },
  },
};
</script>

<style lang="scss" scoped>
#workflow-status {
  display: flex;
  align-items: center;
  color: var(--green);

  &.draft {
    color: var(--orange);
  }
}

.badge {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
  font-size: 10px;
  background: $secondary;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-top: -20px;
  margin-right: -20px;
  position: absolute;
  z-index: 1;
}

#process-item-list {
  .chips {
    background-color: #fff !important;
    margin: 0px !important;
    font-size: 12px !important;
    margin-right: 10px !important;
  }

  .pink-hover:hover {
    background: $pink-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .gray-hover:hover {
    background: $gray-2 !important;
  }

  #action-btn.small {
    width: 22px !important;
    height: 22px !important;
  }

  .ellipsis:hover {
    max-width: none !important;
    overflow: visible;
    white-space: pre;
  }
}
</style>
