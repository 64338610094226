<template>
  <div id="report-list">
    <ItemList
      :columns="columns"
      :items="items"
      :mode="mode"
      :report="true"
      @select="handleOpen"
      @showTable="showTable"
      @viewFile="handleViewFile"
      @openSubWorkflow="openSubWorkflow"
    >
      <template #title="{ item }">
        <div class="row">
          <div
            class="col-auto ellipsis"
            :style="item.requestNo.length > 50 ? 'max-width: 250px' : ''"
          >
            <span
              class="stat"
              :class="item.link ? 'text-underline' : ''"
              @click.stop="handleOpen(item.id)"
            >
              {{ item.requestNo || "-" }}
            </span>
            <template v-if="item.jiraIssueInfo.id">
              <BaseIcon name="mdi-circle-small" color="secondary" />
              <a
                v-tooltip.top="'JIRA Issue Link'"
                :href="getJIRALink(item.jiraIssueInfo)"
                target="_blank"
                style="color: inherit"
              >
                REF# ID: {{ item.jiraIssueInfo.id }}
              </a>
              <BaseIcon name="mdi-circle-small" color="secondary" />
              <span> KEY: {{ item.jiraIssueInfo.key }} </span>
              <template v-if="item.jiraIssueInfo.assignee">
                <BaseIcon name="mdi-circle-small" color="secondary" />
                <span class="text-primary text-bold"
                  >ASSIGNEE: {{ item.jiraIssueInfo.assignee }}</span
                >
              </template>
              <template v-if="item.jiraIssueInfo.issueStatus">
                <BaseIcon name="mdi-circle-small" color="secondary" />
                <span class="text-primary text-bold"
                  >STATUS: {{ item.jiraIssueInfo.issueStatus }}</span
                >
              </template>
            </template>
          </div>
        </div>
        <div
          v-if="mode === 'inbox' && !item.mlPrediction && item.mlCondition"
          class="row q-pt-sm"
        >
          <template v-if="item.mlCondition.indexOf('[') > -1">
            <div
              v-for="row in JSON.parse(item.mlCondition)"
              :key="item.processId + '' + row.fieldName"
              @click.stop="$emit('validation', item.processId)"
            >
              <q-chip
                outline
                :color="
                  row.status === 'Matched'
                    ? 'green'
                    : row.status === 'Not Matched'
                    ? 'red'
                    : 'orange'
                "
                class="chips gray-hover"
                :class="
                  row.status === 'Matched'
                    ? 'green-hover'
                    : row.status === 'Not Matched'
                    ? 'red-hover'
                    : 'orange-hover'
                "
              >
                <q-avatar
                  icon="mdi-robot"
                  class="q-mr-sm"
                  :color="
                    row.status === 'Matched'
                      ? 'green'
                      : row.status === 'Not Matched'
                      ? 'red'
                      : 'orange'
                  "
                  text-color="white"
                />
                {{ row.fieldValue }}
              </q-chip>
            </div>
          </template>
          <template v-else>
            <template v-for="cond in item.mlCondition.split(',')">
              <q-chip :key="cond" outline color="pink" class="chips pink-hover">
                <q-avatar
                  icon="mdi-information-variant"
                  class="q-mr-sm"
                  color="pink"
                  text-color="white"
                />
                {{ cond }}
              </q-chip>
            </template>
          </template>
        </div>
      </template>
      <template #mlcondition="{ item }">
        <div v-if="mode === 'inbox' && item.mlCondition">
          <template v-for="cond in item.mlCondition.split(',')">
            <template v-if="cond.indexOf('Extracted') > -1">
              <q-chip
                :key="cond"
                outline
                color="green"
                class="chips green-hover"
              >
                <q-avatar
                  icon="mdi-information-variant"
                  color="green"
                  text-color="white"
                />
                {{ cond }}
              </q-chip>
            </template>
            <template v-else-if="cond.indexOf('Processing') > -1">
              <q-chip
                :key="cond"
                outline
                color="orange"
                class="chips orange-hover"
              >
                <q-avatar
                  icon="mdi-information-variant"
                  color="orange"
                  text-color="white"
                />
                {{ cond }}
              </q-chip>
            </template>
            <template v-else>
              <q-chip :key="cond" outline color="pink" class="chips pink-hover">
                <q-avatar
                  icon="mdi-information-variant"
                  color="pink"
                  text-color="white"
                />
                {{ cond }}
              </q-chip>
            </template>
          </template>
        </div>
      </template>
      <template #responseTime="{ item }">
        <template v-if="item.responseStatus == 2">
          <span class="text-red"> {{ item.responseTime }}</span>
        </template>
        <template v-else-if="item.resolutionStatus == 1">
          <span class="text-green"> {{ item.responseTime }}</span>
        </template>
        <template v-else-if="item.resolutionStatus == 0">
          <span class="text-orange"> {{ item.responseTime }}</span>
        </template>
      </template>
      <template #responseStatus="{ item }">
        <template v-if="item.responseStatus == 2">
          <BaseIcon
            v-tooltip.top="'Overdue'"
            name="mdi-close-circle"
            color="red"
          />
        </template>
        <template v-else-if="item.responseStatus == 1">
          <BaseIcon
            v-tooltip.top="'WithIn SLA'"
            name="mdi-check-circle"
            color="green"
          />
        </template>
        <template v-else-if="item.responseStatus == 0">
          <BaseIcon
            v-tooltip.top="'In-Progress'"
            name="mdi-progress-alert"
            color="orange"
          />
        </template>
      </template>
      <template #resolutionTime="{ item }">
        <template v-if="item.resolutionStatus == 2">
          <span class="text-red"> {{ item.resolutionTime }}</span>
        </template>
        <template v-else-if="item.resolutionStatus == 1">
          <span class="text-green"> {{ item.resolutionTime }}</span>
        </template>
        <template v-else-if="item.resolutionStatus == 0">
          <span class="text-orange"> {{ item.resolutionTime }}</span>
        </template>
      </template>
      <template #resolutionStatus="{ item }">
        <template v-if="item.resolutionStatus == 2">
          <BaseIcon
            v-tooltip.top="'Overdue'"
            name="mdi-close-circle"
            color="red"
          />
        </template>
        <template v-else-if="item.resolutionStatus == 1">
          <BaseIcon
            v-tooltip.top="'WithIn SLA'"
            name="mdi-check-circle"
            color="green"
          />
        </template>
        <template v-else-if="item.resolutionStatus == 0">
          <BaseIcon
            v-tooltip.top="'In-Progress'"
            name="mdi-progress-alert"
            color="orange"
          />
        </template>
      </template>
      <template #table="{ value }">
        <div>
          <div class="ellipsis">
            <BaseActionButton
              icon="mdi-table-large"
              is-flat
              no-border
              @click="showTable('Table', value)"
            />
          </div>
        </div>
      </template>
      <template #actions="{ item }">
        <div class="row" style="width: 115px">
          <BaseActionButton
            v-tooltip.top="'history'"
            is-flat
            no-border
            icon="mdi-history"
            color="primary"
            @click.stop="openHistory(item.id)"
          />
          <BaseActionButton
            v-tooltip.top="'attachments'"
            is-flat
            no-border
            icon="mdi-attachment"
            color="primary"
            @click.stop="openAttachments(item.id)"
          >
            <span v-if="item.attachmentCount" class="badge"
              >{{ item.attachmentCount }}
            </span>
          </BaseActionButton>
          <BaseActionButton
            v-tooltip.top="'comments'"
            is-flat
            no-border
            icon="mdi-comment-text-outline"
            color="primary"
            @click.stop="openComments(item.id)"
          >
            <span v-if="item.commentsCount" class="badge"
              >{{ item.commentsCount }}
            </span>
          </BaseActionButton>
        </div>
      </template>
      <template #subactions="{ item }">
        <div class="row" style="width: 51%; margin-left: auto">
          <BaseActionButton
            v-tooltip.top="'history'"
            icon="mdi-history"
            color="primary"
            is-flat
            no-border
            class="icon"
            @click="showHistory(item)"
          />

          <BaseActionButton
            v-tooltip.top="'attachments'"
            is-flat
            no-border
            icon="mdi-attachment"
            color="primary"
            @click.stop="$emit('subAttachments', item)"
          >
            <span v-if="item.attachmentCount" class="badge"
              >{{ item.attachmentCount }}
            </span>
          </BaseActionButton>

          <BaseActionButton
            v-tooltip.top="'comments'"
            is-flat
            no-border
            icon="mdi-comment-text-outline"
            color="primary"
            @click.stop="$emit('subComments', item)"
          >
            <span v-if="item.commentsCount" class="badge"
              >{{ item.commentsCount }}
            </span>
          </BaseActionButton>
        </div>
      </template>
    </ItemList>
    <Modal
      v-model="expandTableModal"
      width="60vw"
      height="80vh"
      @input="expandTableModal = false"
    >
      <!-- title -->

      <template #title>{{ tableTitle }}</template>

      <!-- ... -->

      <template #default>
        <div id="tableData">
          <table>
            <thead>
              <tr>
                <th v-for="(value, key) in tableData[0]" :key="key">
                  {{ key }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in tableData" :key="index">
                <td v-for="(value, key) in row" :key="key">
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "ReportList",

  components: { ItemList, Modal },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },

    lists: {
      type: Array,
      required: true,
    },

    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      expandTableModal: false,
      tableTitle: "",
      tableData: [],
    };
  },

  methods: {
    handleOpen(id) {
      let request = {};
      request = this.lists.find((item) => item.processId === id);
      this.$emit("open", request);
    },

    openHistory(id) {
      let request = {};
      request = this.lists.find((item) => item.processId === id);
      this.$emit("history", request);
    },

    openAttachments(id) {
      let request = {};
      request = this.lists.find((item) => item.processId === id);
      this.$emit("attachments", request);
    },

    openComments(id) {
      let request = {};
      request = this.lists.find((item) => item.processId === id);
      this.$emit("comments", request);
    },

    getLabel(id, type) {
      let controlLabel = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (type === "TABLE") {
            const column = field.settings.specific.tableColumns.find(
              (column) => column.id === id
            );
            if (column) {
              controlLabel = column.label;
            }
          } else {
            if (field.id === id) {
              controlLabel = field.label ? field.label : field.type;
              return;
            }
          }
        }
      });
      return controlLabel.toLocaleLowerCase();
    },

    showTable(name, value) {
      this.tableData = [];
      this.tableTitle = name;
      let data = JSON.parse(value);
      for (let item of data) {
        let row = {};
        for (let key of Object.keys(item)) {
          const label = this.getLabel(key, "TABLE");
          row[label] = item[key];
        }
        this.tableData.push(row);
      }
      this.expandTableModal = true;
    },

    getJIRALink(link) {
      let url = new URL(link.self);
      let origin = url.origin.replace(
        "https://jira-external-tools-integration.getgotools.net",
        "https://jira.ops.expertcity.com"
      );
      return `${origin}/browse/${link.key}`;
    },

    handleViewFile(file) {
      this.$emit("viewFile", file);
    },

    openSubWorkflow(item) {
      this.$emit("openSubWorkflow", item);
    },

    showHistory(item) {
      this.$emit("subHistory", item);
    },
  },
};
</script>

<style lang="scss" scoped>
#report-list {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 10px;
    background: $secondary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -20px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .ellipsis:hover {
    max-width: none !important;
    overflow: visible;
    white-space: pre;
  }
}

#modal #tableData {
  table {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid var(--divider-color);
    border-radius: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th {
      border-bottom: 1px solid var(--divider-color);
    }

    tr:not(:first-child) td {
      border-top: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color); /* Add top border to the first row */
    }
  }
}
</style>
